<template>
  <div>
    <van-swipe indicator-color="#980b12" :autoplay="3000">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <img class="one" :src="image" v-lazy="image" />
      </van-swipe-item>
    </van-swipe>
    <div class="a">
      <img class="one" src="../../assets/img/WAP首页_01.jpg" alt="" />
    </div>
    <div class="video">
      <video class="one" controls="" autoplay="" name="media">
        <source
          src="../../assets/img/莱珀妮邓总专访00(8850890).mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <div class="b">
      <img class="one" src="../../assets/img/WAP首页_03.jpg" alt="" />
    </div>
    <div class="c">
      <img class="one" @click="navpath('classic')" src="../../assets/img/WAP首页_05.jpg" alt="" />
    </div>
    <div class="flex-b">
      <div class="d">
        <img class="one" @click="navpath('royal')" src="../../assets/img/WAP首页_06.jpg" alt="" />
      </div>
      <div class="d">
        <img class="one" @click="navpath('plastic')" src="../../assets/img/WAP首页_07.jpg" alt="" />
      </div>
      <div class="e">
        <img class="one" @click="navpath('man')" src="../../assets/img/WAP首页_08.jpg" alt="" />
      </div>
      <div class="e">
        <img class="one" @click="navpath('carved')" src="../../assets/img/WAP首页_09.jpg" alt="" />
      </div>
    </div>
    <div class="f">
      <img class="one" src="../../assets/img/WAP首页_10.jpg" alt="" />
    </div>
    <div class="g">
      <img class="one" src="../../assets/img/WAP首页_11.jpg" alt="" />
    </div>
    <div class="h">
      <img class="one" src="../../assets/img/WAP首页_12.jpg" alt="" />
    </div>
    <div class="i">
      <img
        class="one"
        @click="link(1)"
        src="../../assets/img/WAP首页_13.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload, Swipe, SwipeItem } from "vant";
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
export default {
  data() {
    return {
      images: [require("@/assets/img/海报部分.png")],
    };
  },
  methods: {
    link(id) {
      this.$router.push({
        path: `newsList`,
        query: {
          id: id,
        },
      });
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    navpath(path) {
      this.$router.push(path);
      this.demo = false;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.my-swipe .van-swipe-item {
  font-size: 20px;
  text-align: center;
  .my-swipe-item {
    width: 375px;
    height: 400px !important;
    img {
      width: 375px;
      height: 400px;
    }
  }
}
.i {
  height: 257px;
  width: 100%;
}
.h {
  height: 150px;
  width: 100%;
}
.g {
  height: 301px;
  width: 100%;
}
.f {
  height: 180px;
  width: 100%;
}
.d {
  width: 50%;
  height: 315px;
}
.e {
  width: 50%;
  height: 347px;
}
.a {
  height: 110px;
  width: 100%;
}
.b {
  height: 298px;
  width: 100%;
}
.video {
  padding: 0 22px;
  height: 190px;
}
.c {
  height: 414px;
  width: 100%;
}
</style>